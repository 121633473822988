import { Order } from "./Order";
import { OrderBasics } from "./OrderBasics";
import { OrderStatus } from "./OrderStatus";

export class Campaign extends OrderBasics {
    orderIds: Order["id"][];
    // TODO 9383 sync status
    status: OrderStatus;
    orderRoles: { [orderId: Order["id"]]: OrderRole };

    constructor(initialValues?: Partial<Campaign>) {
        super(initialValues);
        this.orderIds = initialValues?.orderIds ?? [];
        this.status = initialValues?.status ?? OrderStatus.DRAFT;
        this.orderRoles = initialValues?.orderRoles ?? {};
    }
}

export enum OrderRole {
    LEADING = "OrderRole_LEADING",
    FOLLOWING = "OrderRole_FOLLOWING",
}
